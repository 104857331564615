// in gastby-browser.js
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  console.log(window.location.hash);
  if (!window.location.hash) {
    window.scrollTo(0, 0);
  } else {
    document.querySelector(window.location.hash).scrollIntoView();
  }

  return false;
};
